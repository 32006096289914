import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const defaultRoutes = [
  {
    name: "Dashboard",
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/Default.vue"),
    meta: {
      authRequired: false,
    },
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "Gust dashboard",
          path: "/dashboard?unauthenticated",
          component: () => import("@/views/Default.vue"),
          meta: {
            authRequired: false,
          },
        },
        {
          name: "Auth dashboard",
          path: "/dashboard?authenticated",
          component: () => import("@/views/Default.vue"),
          meta: {
            authRequired: true,
          },
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-signout",
          path: "/oauth2-signout",
          component: (): Component => import("../components/OAuth2Signout.vue"),
        },
        {
          name: "logout",
          path: "/logout",
          component: (): Component => import("../components/Logout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../components/Failed.vue"),
        },
      ]
    : [];
